import * as React from "react"
import { Link } from "gatsby"

const Footer = () => {
	return (
		<footer
			style={{
				marginTop: `2rem`,
				textAlign: `center`,
			}}
		>
			<p>
				<Link to="/" style={{ color: `inherit`, textDecoration: `none` }}>Home</Link>
			</p>
			<p>
				© {new Date().getFullYear()} built by
				{` `}
				<a style={{ color: `inherit`, textDecoration: `none` }} target="_blank" rel="noreferrer" href="https://www.mattrh.com">mattrh</a>
			</p>
		</footer>
	);
}

export default Footer;
