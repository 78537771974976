/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "./footer";
import "./layout.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const url = typeof window !== 'undefined' ? window.location.href : '';
	const urlParts = url.split("/");
	let currentPage = "";
	if (urlParts[urlParts.length-1].length === 0) {
		currentPage = urlParts[urlParts.length-2];
	} else {
		currentPage = urlParts[urlParts.length-1];  
	}
	
  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Nicholas Yiannakis`} currentPage={currentPage}/>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
