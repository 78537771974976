import * as React from "react";
import { Link } from "gatsby";

import './navbar.css';

const Navbar = ({ currentPage }) => {
	return(
		<nav className={"main-navbar"}>
			<div className="navbar-container">
				<ul>
					<li>
						<Link to="/works/" className={`nav-link-text ${currentPage === "works" && `active`}`}>Works</Link>
					</li>
					<li>
						<Link to="/contact/" className={`nav-link-text ${currentPage === "contact" && `active`}`}>Contact</Link>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default Navbar;
